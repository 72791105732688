import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './Consultoria.css'
import FaleConosco from './FaleConosco'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}> <h4 className="tituloservicoconsultoria" >  <span><i class="fas fa-arrow-alt-circle-right"> </i> </span> Assessoria empresarial</h4> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p className="accordion_text"> Reestruturação da  sua empresa no menor espaço de tempo possível do ponto de vista organizacional com profissionais preparados e qualificados. Identificação dos pontos fracos e fortes nas áreas administrativas, de pessoal e global, estratégia, projeto, aplicação do projeto e acompanhamento do desenvolvimento em todos os campos e processos da empresa. Objetivo, organização, readequação e busca do equilíbrio sustentável da empresa . Agende um visita em sua empresa e veja o que nossa equipe é capaz de realizar com você.
          </p>
            <FaleConosco />
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography
            className={classes.heading}><h4 className="tituloservicoconsultoria" >  <span><i class="fas fa-arrow-alt-circle-right"> </i> </span> Seleção e recrutamento com avaliação psicológica</h4> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p className="accordion_text"> Planejamento, visita a empresa, analise de cargo, entrevista, provas e testes psicológicos, análise de resultados, parecer psicológico.</p>
            <FaleConosco />

          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><h4 className="tituloservicoconsultoria" >  <span><i class="fas fa-arrow-alt-circle-right"> </i> </span> Workshop </h4> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p className="accordion_text"> Realizamos palestras que apresentam conteúdo sobre sua área de expertise, qualificação de profissionais. Confira em nossos canais de rede social instagram e facebook o proximo evento.</p>

            <FaleConosco />

          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><h4 className="tituloservicoconsultoria" >  <span><i class="fas fa-arrow-alt-circle-right"> </i> </span> Treinamentos com certificado</h4> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p className="accordion_text"> Nosso objetivo é preparar profissionais para executar suas atividades com excelência dentro da empresa. Acesse nossas redes sociais no instagram e facebook e veja quais treinamentos e turmas estão disponíveis.</p>

            <FaleConosco />

          </Typography>
        </AccordionDetails>
      </Accordion>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}><h4 className="tituloservicoconsultoria" >  <span><i class="fas fa-arrow-alt-circle-right"> </i> </span> Sublocação de sala para treinamento</h4> </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p className="accordion_text"> Entre em contato conosco e solicite seu orçamento. Teremos prazer em atender sua demanda.</p>
            <FaleConosco />
          </Typography>
        </AccordionDetails>
      </Accordion>






    </div>
  );
}