import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
//import Logo from './images/logo.png'

import './Navbar.css'




export default function Header() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false)
  const [button, setButton] = useState(true);



  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);




  window.addEventListener('resize', showButton);



  return (

    <>
      <nav className='navbar'>
        <div className='navbar-container'>

          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>

            <img src="./logo.svg" alt="J&J Caldeiraria" />



          </Link>

          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>





          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'><Link to='/' className='nav-links' onClick={closeMobileMenu}>Home</Link></li>
            <li className='nav-item'><Link to='/' className='nav-links' onClick={closeMobileMenu} >Serviços</Link></li>
            <li className='nav-item'><a href='https://forms.gle/Dm6ima2FXkKCLjcf9' className='nav-links' onClick={closeMobileMenu} >Banco de talentos</a></li>
            
            <li className='nav-item'><Link to='/contato' className='nav-links' onClick={closeMobileMenu}>Contato</Link></li>
          </ul>

        </div>
      </nav>
    </>

  )
}