import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className="footerContainer">
      <div className="footerContainerContent">
        <div className="footerDados">
          <div className="footerLogo">
            <img src="./logo.svg" alt="JeJ Caldeiraria" />
          </div>
          <div className="footerDesc">
            <p className="endereco">
            Galeria Ubiratan Geraldo – <br />
              Rua dos Martírios nº25, Sala 07, Terreo
              Centro - Goiana - PE
              
            </p><br />
            <p>
              <i class="fas fa-envelope"> </i> <span > contato@capconsult.com.br</span><br />
              <i className="fas fa-envelope">  </i> <span > cap.consult@hotmail.com
              </span>
            </p><br />
            <p>
              <i className="fas fa-phone-alt"></i><span> 81 98222-8971</span>
            </p>
            <div className="social">
             <p><i class="fab fa-linkedin-in fa-2x" ></i></p>
             <p><a className="linksocial" href="https://www.instagram.com/consultcap/"><i class="fab fa-instagram fa-2x"></i></a></p>
             <p><a className="linksocial" href="https://www.facebook.com/CAP-Consult-Cl%C3%ADnica-de-Avalia%C3%A7%C3%A3o-Psicodiagn%C3%B3stico-106485487939363/"><i class="fab fa-facebook-square fa-2x"></i></a></p>
            </div>
          </div>
        </div>
        <div className="footerServicos">
          <div className="footerServicosTitulo">Serviços de psicologia</div>
          <div className="footerServicosItems">
            <ul>
              <li><i class="fas fa-caret-right"> </i> Psicoterapia individual</li>
              <li><i class="fas fa-caret-right"> </i>  Psicoterapia para casal</li>
              <li><i class="fas fa-caret-right"> </i>  Terapia familiar</li>
              <li><i class="fas fa-caret-right"> </i>   Avaliação psicológica</li>
              <li><i class="fas fa-caret-right"> </i>    Avaliação psicodiagnóstico</li>
              <li><i class="fas fa-caret-right"> </i>  Avaliação neuropsicológica</li>
              <li><i class="fas fa-caret-right"> </i>    Avaliação do desempenho escolar e aprendizagem</li>
              <li><i class="fas fa-caret-right"> </i>   Atendimento on-line </li>
              <li><i class="fas fa-caret-right"> </i>    Atendimento domiciliar </li>
              <li><i class="fas fa-caret-right"> </i>    Orientação profissional </li>
              <li><i class="fas fa-caret-right"> </i>     Psicologia do esporte e exercício </li>
            </ul>
          </div>
        </div>







        <div className="footerServicos">
          <div className="footerServicosTitulo">Serviços de consultoria</div>
          <div className="footerServicosItems">
            <ul>
              <li><i class="fas fa-caret-right"> </i> Assessoria empresarial</li>
              <li><i class="fas fa-caret-right"> </i>  Seleção e recrutamento com avaliação psicológica</li>
              <li><i class="fas fa-caret-right"> </i>   Workshop</li>
              <li><i class="fas fa-caret-right"> </i>   Treinamentos com certificado</li>
              <li><i class="fas fa-caret-right"> </i>     Sublocação de sala para treinamento</li>






            </ul>
          </div>
        </div>












      </div>

    </div>
  )
}

export default Footer
