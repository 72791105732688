import React from 'react'
import './BannerPortfolio.css'

function BannerPortfolio() {
  return (
    <>
      <div className="BannerContainer">
        <div className="conteudo">

          <div className="texto">
            
              <h1>Portfólio de Serviços </h1>
            <p>Tenha nossos serviços na palma de sua mão.<br/> Confira nosso Portfólio de Serviços. </p>
                        
  <a href="https://drive.google.com/file/d/1I-E36VQ3LzITtTqRgyiUBQHv8Jx04Ui-/view?usp=sharing"  >
    
  <button className="buttonDonwload"> <i class="fas fa-cloud-download-alt"></i> Baixar Portfólio de Serviços</button></a>
              </div>

            
            
            
              
            
       



        </div>








      </div>
    </>
  )
}

export default BannerPortfolio
