import React from 'react'
import BannerPortfolio from '../BannerPortfolio'
import Gallery from '../Gallery'
function Portfolio() {
  return (
    <div>
      
      <BannerPortfolio />
      
<Gallery />
    </div>
  )
}

export default Portfolio
