import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/pages/Home'
import Footer from './components/Footer'
import Contato from './components/pages/Contato'
import Servicos from './components/pages/Servicos'
import SubFooter from './components/SubFooter'
import Portfolio from './components/pages/Portfolio'

//import BotaoFloat from './components/BotaoFloat'
function App() {
  return (
    <>
    
      <Router>
        <Navbar />
        <Switch>

          <Route path="/" exact component={Home} />
          <Route path="/Contato" exact component={Contato} />
          <Route path="/Portfolio" exact component={Portfolio} />
          <Route path="/Servicos" exact component={Servicos} />

        </Switch>
       
        <Footer />
        <SubFooter />
      </Router>



    </>
  );
}

export default App;
