import React, { Component } from 'react'
import emailjs from 'emailjs-com'
import './mail.css'







//Mail funcion
function sendEmail(e) {

  e.preventDefault();
  emailjs.sendForm('service_wqnpxsq', 'template_8kupjbe', e.target, 'user_4rR6gsP4NO8ifOUVj87dw')
  
    .then((result) => {
      console.log(result.text);
      alert('Mensagem Enviada com Sucesso')



    }, (error) => {
      console.log(error.text);
    });
  e.target.reset()
}



class Mail extends Component {




  render(props) {

    return (
      <form className="contact-form" onSubmit={sendEmail}>

        <h1 className="h1FormAtendimento_st">Email</h1>




        <div className="containerFiels">
          <input type="hidden" name="contact_number" />


          <div className="Fiels">
            <div className="label_st">
              <label>Nome</label>
            </div>
            <div className="label_st">
              <input type="text" name="name" placeholder="Seu nome..." required  className="fieldContato"/>
            </div>
          </div>


          <div className="Fiels">
          <div className="label_st">
            <label>Telefone</label>
          </div>
          <div className="label_st">
            <input type="number" name="telefone" placeholder="Seu telefone..." required  
       className="fieldContato"/>
          </div>
        </div>


          <div className="Fiels">
            <div className="label_st">
              <label>Email</label>
            </div>
            <div className="label_st">
              <input type="email" name="email" placeholder="Seu email..." required className="fieldContato"/>
            </div>
          </div>

          {/* 
          <div className="Fiels">
            <div className="label_st">
              <label>Assunto</label>
            </div>
            <div className="label_st">

              <input type="text" name="subject" placeholder="Assunto ou título da mensagem"  />
            </div>
          </div>

*/}

        </div>










        <div className="Fiels">
          <div className="label_st">
            <label>Mensagem</label>
          </div>

          <div className="label_st">
            <textarea name="message" placeholder="Gostaria de saber os valores para o serviço..." required className="fieldContatoMensagem"/>
          </div>
        </div>
        <div className="label_Button_st">
          <input type="submit" value="Enviar" className="buttonEnvuar"/>
        </div>

      </form>
    );

  }
}


export default Mail










































