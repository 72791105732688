import React from 'react'
import './MoreServices.css'
function MoreServices() {
  return (
    <div className="msContainer">
      <div className="msContainerDescription">
        <div>
          <div className="msTitle">
            <h1>Outros Serviços Executados</h1>
          </div>
          <div className="msText">
            <ul>
              <li>Adequações de Instalações Industrias</li>
              <li>Fabricação  de Tubulações Industriais</li>
              <li>Fabricação  e Instalação de Estrutura em Aço Inox</li>
              <li>Manutenção de Redes de Energia Elétrica</li>
              <li>Construção Civil</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  )
}

export default MoreServices
