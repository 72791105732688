import React from 'react'
import './Clientetems.css'
function ClientesItems(props) {
  return (
    <div className="clientesItems">
     
      <div className="clientesItemsImage"><img src={props.image} alt={props.alt} className="images"/></div>
      
    </div>
  )
}

export default ClientesItems
