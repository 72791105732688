import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import './ServicesItemCard.css'

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar'


import Typography from '@material-ui/core/Typography'
//import { blue } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({



  media: {
    height: 0,
    paddingTop: '56.25%',
    
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
     
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    //   backgroundColor: blue[600],
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

}));

export default function ServicesItemCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (


    <div className="ServicesItemSpaceCard_st">
      <Card className={classes.root} onClick={handleExpandClick}
        aria-expanded={expanded} >

        <CardHeader avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            <img src={props.icon} alt="" />
          </Avatar>
        }

          title={<h1 className="tituloServicoCard_st">{props.tituloServico}</h1>}
          subheader={<h1 className="subtituloServicoCard_st">{props.subtituloServico}</h1>}

        />




        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <CardMedia
              className={classes.media}
              image={props.image}
              title="Paella dish"
            />
            <Typography paragraph><br /></Typography>

            <Typography paragraph>
              {props.content}
            </Typography>

          </CardContent>
        </Collapse>

      </Card>
    </div>
  );
}


// function ServicesItemCard(props) {
  // return (
    // <div className="ServicesItemCard_st">
      // <div className="icoServico_st">
        // <img src={props.icon} alt={props.alt} />
      // </div>
      // <div className="tituloServico_st">
        // <h1>{props.tituloServico}</h1>
      // </div>
    // </div>
  // )
// }

// export default ServicesItemCard





// function ServicesItemCard(props) {
  // return (
    // <div className="ServicesItemCard_st">
      // <div className="icoServico_st">
        // <img src={props.icon} alt={props.alt} />
      // </div>
      // <div className="tituloServico_st">
        // <h1>{props.tituloServico}</h1>
      // </div>
    // </div>
  // )
// }

// export default ServicesItemCard



