import React from 'react'
import './ServicoConsultoria.css'
import  Consultoria from './Consultoria'
function ServicoConsultoria() {
  return (
    <div className="consultcontainer">
      <div className="consultcontainer2">
        <div className="consultcontainer_desc">
          <h1 className="titleconsult_st">Serviços de consultoria</h1>
         <p>Seja no campo pessoal ou profissional, nós da Cap Consult estamos prontos para apoiar suas iniciativas para que se possa atingir o máximo de resultados.</p>
        </div>

        <div className="consultcontainer_content"> 
          
          
          <div className="consultcontainer_accordeon">
            <Consultoria />
          
          </div>
          <div className="consultcontainer_image">
          <img src="/images/bc1.png"/>
          </div>
        
        </div>




      </div>

    </div>
  )
}

export default ServicoConsultoria
