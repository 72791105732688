import React from 'react'
import './Institucional.css'



function Institucional() {
  return (
    <div className="institucionalContainer" >

      <div>
        <div className="institucional">


          <div className="institucionalMissao">
            <div className="institucionalTitulo">Missão</div>
            <div className="institucionalTexto">
            Produzir resultados profissionais que atendam de forma eficiente e satisfatória seus clientes, levando em consideração a saúde e a formação integral do ser humano, fortalecendo seu crescimento pessoal e o desenvolvimento de uma sociedade mais justa e solidária, respeitando a diversidade, a ética e o meio ambiente.

        </div>
          </div>



          <div className="institucionalVisao">
            <div className="institucionalTitulo">Visão</div>
            <div className="institucionalTexto">
            Ser reconhecida como instituição de excelência no atendimento de seus clientes, na prestação de serviços em psicologia e saúde integral, na formação de indivíduos mais conscientes de sua condição humana e de seu papel como agente transformador de contingências pessoais e sociais.


        </div>
          </div>


          <div className="institucionalValores">
            <div className="institucionalTitulo">Valores</div>
            <div className="institucionalTexto">
            Temos como valores que orientam nossas ações e estratégias: profissionalismo, ética, eficácia, respeito e solidariedade



         </div>
          </div>




        </div>
      </div>
    </div>
  )
}

export default Institucional
