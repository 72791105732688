import React from 'react'
import './SubFooter.css'
function SubFooter() {
  return (
    <div className="copySubFooter">
     
      <div className="copy">
        <div className="copyContainer">
        <div>Cap-Consult todos os direitos reservados </div>
        
        </div>
      </div>
     
    </div>
  )
}

export default SubFooter
