import React from 'react'
import ServicesItemCard from './ServicesItemCard'
import './ServicesItems.css'
import FaleConosco from './FaleConosco'
//import Incendio from './images/svg/1.svg'

function ServicesItems() {
  return (
    <>
      <div className="container">
        <div className="container2">
          <h1 className="titleServicesItems_st">Serviços de psicologia
       </h1>
          <div className="ServicesItems_st">

            <div className="box1_st">



              <ServicesItemCard

                tituloServico={"Psicoterapia individual "}
                subtituloServico={""}
                image={'./images/individual.jpg'}
                alt={"Psicoterapia individual em Goiana"} icon={'./images/0.svg'}
                content={
                  <div>
                

                    <p>
                    Criança, Adolescente, Adulto e Idoso
                    </p>
<FaleConosco/>
                  </div>}
              />



              <ServicesItemCard
              tituloServico={"Psicoterapia para casal"}
              subtituloServico={""}
              image={'./images/casal.jpg'}
              alt={"Psicoterapia para casal em Goiana"} icon={'./images/0.svg'}
              content={
                <div>
              
                  <p>
                    A psicoterapia para casal é um recurso terapêutico que os casais podem buscar quando identificam que precisam de auxílio para lidar com questões que estão interferindo negativamente em seu relacionamento afetivo.

                  </p>
                  <FaleConosco/>
                </div>}
            />


              
            <ServicesItemCard
            tituloServico={"Terapia familiar"}
            subtituloServico={""}
            image={'./images/familia.jpg'}
            alt={"Terapia familiar em Goiana"} icon={'./images/0.svg'}
            content={
              <div>
            
                <p>
                  A terapia familiar e indicada como alternativa para famílias que estão passando por momentos de conflitos e desarmonia.

                   
                   
                </p>
                <FaleConosco/>
              </div>}
          />
              
              
              
                   
   <ServicesItemCard
   tituloServico={"Avaliação psicológica"}
   subtituloServico={""}
   image={'./images/avaliacao.jpg'}
   alt={"Avaliação psicológica em Goiana"} icon={'./images/0.svg'}
   content={
     <div>
   
       <p>
         A Avaliação Psicológica nada mais é que um procedimento usado por profissionais da área da psicologia com o fim de avaliar a saúde psicológica de uma pessoa. A mesma é feita através de alguns instrumentos como testes, entrevistas, análise de dados e observações.


       </p>
       <FaleConosco/>
     </div>}
 />
              
              
              



            </div>



















            <div className="box2_st">
              <ServicesItemCard
                tituloServico={"Avaliação psicodiagnóstico "}
                subtituloServico={""}
                image={'./images/avaliacao2.png'}
                alt={"Serviços técnicos especializados"}
                icon={'./images/0.svg'}
                content={
                  <div>


                    <p>
                      E o método de diagnóstico realizado através da aplicação de testes psicológicos e da interpretação do comportamento humano, cujo a junção da observação de comportamentos e resultados dos instrumentos aplicados darão ao psicólogo indicadores de um sintoma, uma possível psicopatologia, avaliando também traços de personalidade e do caráter do indivíduo.

                    </p>
                    <FaleConosco/>
                  </div>

                }

              />



              <ServicesItemCard
              tituloServico={"Avaliação neuropsicológica"}
              subtituloServico={""}
              image={'./images/avaliacao3.jpg'}
              alt={"Avaliação neuropsicológica em Goiana"}
              icon={'./images/0.svg'}
              content={
                <div>
                  <p>
                    A avaliação neuropsicológica é um exame não invasivo que permite o psicólogo avaliar através de testes Neuropsicológicos que mostraram com mais detalhe o desempenho do funcionamento cerebral e suas funções cognitivas, relacionados a suspeitas de alterações cognitivas que podem ser decorrentes de desordens neurológicas, transtornos e ou lesões cerebrais.




                  </p>
                  <FaleConosco/>
                </div>
              }
            />




            <ServicesItemCard
            tituloServico={"Avaliação do desempenho escolar e aprendizagem "}
            subtituloServico={""}
            image={'./images/escola.png'}
            alt={"Avaliação do desempenho escolar e aprendizagem  em Goiana"}
            icon={'./images/0.svg'}
            content={
              <div>
                <p>
                  Avaliação do desempenho escolar e aprendizagem é necessário quando o professor e ou os pais percebem dificuldades no desempenho e aprendizagem do aluno. A avaliação é realizada através de  instrumentos psicológicos (teste) e observação, cujo o objetivo é identificar as possíveis causas que estão impedindo no momento evolução do aluno (indivíduo) no decorrer do processo de ensino-aprendizagem.




                </p>
                <FaleConosco/>
              </div>
            }
          />




          <ServicesItemCard
          tituloServico={"Atendimento psicológico on-line"}
          subtituloServico={""}
          image={'./images/online.png'}
          alt={"Atendimento on-line  em Goiana"}
          icon={'./images/0.svg'}
          content={
            <div>
              <p>
                A realização do atendimento psicológico online é realizada pelo Skype ou pelo aplicativo Zoom.







              </p>
              <FaleConosco/>
            </div>
          }
        />










            </div>




            <div className="box3_st">


              <ServicesItemCard
                tituloServico={"Atendimento psicológico domiciliar"}
                subtituloServico={""}
                image={'./images/home.png'}
                alt={"Atendimento psicologico domiciliar em Goiana"}
                icon={'./images/0.svg'}
                content={
                  <div>
               

                    <p>
                    É indicado para os casos de  home care, internação domiciliar e  pacientes que desejam ser atendidos no domicílio, é necessário que no atendimento domiciliar tenha um ambiente propício, silencioso para intervenção psicológica e ou acompanhamento psicoterápico.

                    </p>
                    <FaleConosco/>
                  </div>

                }

              />



              <ServicesItemCard
              tituloServico={"Orientação profissional"}
              subtituloServico={""}
              image={'./images/orientacao.png'}
              alt={"Orientação profissional em Goiana"}
              icon={'./images/0.svg'}
              content={
                <div>
                 
                  <p>
                    A Orientação Profissional ou Carreira, não visa determinar qual será sua profissão para sempre, mas sim proporcionar o autoconhecimento, identificar ações para melhores escolhas profissionais. 

                     
                     

                    </p>
                    <FaleConosco/>
                </div>
              }
            />



            <ServicesItemCard
            tituloServico={"Psicologia do esporte e exercício"}
            subtituloServico={""}
            image={'./images/esporte.png'}
            alt={"Psicologia do esporte e exercício em goiana"}
            icon={'./images/0.svg'}
            content={
              <div>
               
                <p>
                  O objetivo do psicólogo do esporte é entender como os fatores psicológicos influenciam o desempenho físico e compreender como a participação nessas atividades afeta o desenvolvimento emocional, a saúde e o bem estar de uma pessoa nesse ambiente.


                   
                  </p>
                  <FaleConosco/>
              </div>
            }
          />






            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesItems
