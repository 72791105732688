import React from 'react'
import './SobreNos.css'

function SobreNos() {
  return (
    <div className="SobreNoscontainer">
      <div className="SobreNoscontainer2">






        <div className="SobreNoscontainer_content">




          <div className="SobreNoscontainer_image">
            <img src="/images/bc2.png" />
          </div>


          <div className="SobreNoscontainer_accordeon">
            <div className="SobreNoscontainer_desc">
              <h1 className="titleSobreNos_st">Sobre nós</h1>
              <div className="SobreNoscontainer_desc_text">
                <p>
                  A Cap Consult é uma clínica psicológica, localizada em Goiana - PE.  Nós atendemos as mais diversas especialidades psicológicas através de uma equipe multidisciplinar.
                  </p> <br/>
                <p>
                  Acreditamos que a saúde mental importa,  seja ela  no campo pessoal, profissional e em qualquer idade. Por esse motivo procuramos atender as mais diversas especialidades como: Psicoterapia individual (Criança, Adolescente, Adulto e Idoso), Psicoterapia para casal, Terapia familiar, Avaliação psicológica, Avaliação psicodiagnóstico, Avaliação neuropsicológica, Avaliação do desempenho escolar e aprendizagem, Orientação profissional, Psicologia do esporte e exercício. </p>
                <p><br/>
                  Além do atendimento na área psicológica, atuamos na área de consultoria empresarial entre alguns dos serviços prestados estão: Assessoria empresarial,  Seleção e recrutamento com avaliação psicológica, Workshop, Treinamentos com certificado, Sublocação de sala para treinamento.
                  </p>


              </div>
            </div>

          </div>






        </div>




      </div>

    </div>
  )
}

export default SobreNos
