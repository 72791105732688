import React from 'react'
import './Banner.css'

function Banner() {
  return (
    <>
      <div className="Banner_st">
        <div className="bcBanner_st">

          <div className="intro">
            <h1>Tudo o que você precisa em um só lugar, afinal, saúde mental importa.</h1>

          </div>









        </div>

        <div className="horacontainer">
          <div className="horacontainer2">
            
            
            
            
            
            <div className="horacontainer_content">
               <div className="icon"><i class="fas fa-phone-alt"></i></div>
              <div className="content">
                <h1 className="horatitle">Tem alguma dúvida? Liga pra gente   </h1>
                <a href="tel:+55-81-98222-8971" >(81) 98222-8971</a>
              </div>
            </div>


            <div className="horacontainer_content2">
            <div className="icon"><i class="fas fa-clock"></i></div>
           <div className="content">
             <h1 className="horatitle">Horário de atendimento</h1>
             <p>segunda a sexta das 08:00 as 18:00</p>
           </div>
         </div>




         <div className="horacontainer_content">
         <div className="icon"><i class="fab fa-whatsapp"></i> </div>
        <div className="content">
          <h1 className="horatitle">Entre em Contato via whatsapp</h1>
          <a href="https://api.whatsapp.com/send?phone=5581982228971" >(81) 98222-8971</a>
        </div>
      </div>


          </div>
        </div>






      </div>
    </>
  )
}

export default Banner
