import React from 'react'
import Banner from '../Banner'
import { Container } from 'semantic-ui-react'
//import AboutUs from '../AboutUs'
//import ServicesItemsCard from '../ServicesItemsCard'
////import Numbers from '../Numbers'
import Institucional from '../Institucional'
import ServicoConsultoria from '../ServicoConsultoria'
//import ClientesItemsCard from '../ClienteItemsCard'
import './Home.css'
import ServicesItems from '../ServicesItems'
import SobreNos from '../SobreNos'

function Home() {
  return (
    <div>
      <Banner />


      <div className="center">
        <ServicesItems />

        <ServicoConsultoria />
        <SobreNos />
        <Institucional />
      </div>


    </div>
  )
}

export default Home
