import React from 'react'
import './FaleConosco.css'
function FaleConosco() {
  return (
    <div className="faleconosco_container">
      <div className="faleconosco_description">
        <div className="faleconosco_description_title">
          <h1>Você está interessado neste serviço? Então fale conosco</h1>
        </div>
        <div className="button_container">
        <div className="faleconosco_button">
          <h1> <a href="https://api.whatsapp.com/send?phone=5581982228971" >Falar agora</a></h1>
          </div>
          </div>
      </div>
    </div>
  )
}

export default FaleConosco
