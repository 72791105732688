import React from 'react'
//import ServicesItemsCard from '../ServicesItemsCard'
import ClientesItemsCard  from '../ClienteItemsCard'
import MoreServices from '../MoreServices'
function Servicos() {
  return (
    <div>
      
     
      <MoreServices />
      <ClientesItemsCard />
    </div>
  )
}

export default Servicos
