import React from 'react'
import Mail from './mail/mail'
import './Contato.css'

function Contato() {
  return (
    <div className="contatoContainer">
      <div className="contatoContainerContent">
        <div className="conatoDesc">
          <div className="conatoDescTitulo">Entre em Contato Conosco</div>
          <div className="conatoDescTexto">
            <p>Contamos com uma equipe de profissionais altamente qualificada que estará pronta para atender suas necesidades .
            </p>
          </div>
        </div>

        <div className="contatoContent">
          <div className="contatoDados">
            <div>
             <a href="https://goo.gl/maps/z341HPpTYXSouJQo7" className="fone"> <p><i class="fas fa-compass"></i> Galeria Ubiratan Geraldo –
             Rua dos Martírios nº25, Sala 07, Terreo Centro - Goiana - PE</p></a>
             <a href="https://goo.gl/maps/z341HPpTYXSouJQo7" > <button className="buttoncomoChegar"><i class="fas fa-location-arrow"></i><span> Como Chegar</span></button></a>
            </div>
            <div className="mail">
              <i className="far fa-envelope">  </i> <span>contato@capconsult.com.br</span> <br/>
              <i className="far fa-envelope">  </i> <span>cap.consult@hotmail.com
              </span>
            </div>
            
            <div className="tel1">
              <p className="contatoNome">Central de Atendimento </p>
              <a href="tel:+5581982228971" className="fone"><p><i className="fas fa-phone-alt"></i> (81) 8222-8971</p></a>
              <a href="https://api.whatsapp.com/send?l=pt-BR&phone=5581982228971" className="fone"><p><i class="fab fa-whatsapp"></i> (81) 8222-8971 </p></a>
            </div>
           
           
           
           
           
           
           
           
           
           

          </div>
          <div className="contatoForm">
            <Mail />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Contato
