import React from 'react'

import { Button, Icon } from 'semantic-ui-react'
import Iframe from 'react-iframe'
import './Gallery.css'

function Gallery() {
  return (
    <div className="Gallery_st">



    <div className="GalleryContainer">
    <div className="numberDescription">
    <div className="numberTitle">Trabalhos Recentes</div>
    <div className="numberText">
       <p> Estamos a  5 anos no mercado de trabalho, nos empenhando ao máximo para trabalharmos com mais<br /> qualidade, 
  segurança e assim satisfazendo as necessidades dos nossos clientes.</p>
    </div>
  </div>
        
        
        
      <Iframe url="../Gallery.html"
        width="100%"


        id="GaleriaServicos"
        className="GaleriaServicos"
        display="initial"
        position="relative"
        scrolling="no"
        frameBorder="0"
        title="GaleriaServicos"

      />
      
        <div className="galeriaCompleta">
          <a href="https://www.instagram.com/jejcaldeiraria/" alt="Galeria de Serviços Industriais"> <Button  className="insta">
            <Icon name='instagram' /> Confira a galeria completa no Instagram
        </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Gallery
