import React from 'react'
import ClientesItems from './ClientesItems'
import './ClientesItemsCard.css'

function ClientesItemsCard() {
  return (
    <div>
      <div className="containerClientesItemsCardPrincipal">
<div>
        <div className="numberDescription">
          <div className="clientesTitle">Nossos Clientes</div>
          <div className="numberText">


          </div>
        </div>


        <div className="containerClientesItemsCard">


          <ClientesItems
            image="./images/1.png"
            alt="Klabin"
          />


          <ClientesItems
            image="./images/2.png"
            alt="Caldeiraria Pesada"
          />

          <ClientesItems
            image="./images/3.png"
            alt="Caldeiraria Pesada"
          />

          <ClientesItems
            image="./images/4.png"
            alt="Caldeiraria Pesada"
          />


          <ClientesItems
            image="./images/5.png"
            alt="São Bras"
          />
          <ClientesItems
            image="./images/6.png"
            alt="Cavalcanti Petribu"
          />
          <ClientesItems
            image="./images/7.png"
            alt="São José"
          />










          </div>
          
          </div>
      </div>
    </div>
  )
}

export default ClientesItemsCard
